$(window).on('load', function() {
  // サイドバーのテンプレート
  var sidebarTemplates = {
    1: `
      <nav>
        <ul class="l-side-header__list">
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">店舗管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -store-list" href="/store/list/">店舗一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -store" href="/store/regist/">店舗登録</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">動画管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-list" href="/movie/list/">動画一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie" href="/movie/regist/">動画登録</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-category" href="/movie/category/regist/">動画カテゴリ</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">ユーザー管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -profile" href="/mypage/">マイページ</a></li>
        </ul>
      </nav>
    `,
    2: `
      <nav>
        <ul class="l-side-header__list">
          <li class="l-side-header__item"><a class="l-side-header__link -session" href="/session/">セッション</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-list" href="/movie/list/">動画一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -profile" href="/mypage/">マイページ</a></li>
        </ul>
      </nav>
    `,
    3: `
      <nav>
        <ul class="l-side-header__list">
          <li class="l-side-header__item"><a class="l-side-header__link -store-list" href="/store/list/">店舗一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -store" href="/store/regist/">店舗登録</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -profile -current" href="/mypage/">マイページ</a></li>
        </ul>
      </nav>
    `
  };

  // data-sidebar-type属性からサイドバーのタイプを取得
  var sidebarType = $("#sidebar").data("sidebar-type");

  // 選択されたサイドバーを挿入
  $("#sidebar").html(sidebarTemplates[sidebarType]);

  // data-current-class属性から-currentクラスを追加するリンクのクラスを取得
  var currentClass = $("#sidebar").data("current-class");

  // 現在のリンクに-currentクラスを追加
  $("#sidebar .l-side-header__link.-" + currentClass).addClass("-current");

  // サイト名をロード
  $("#sitename").load('/common/sitename.html', function(response, status, xhr) {
    if (status == "error") {
      console.error("Error loading sitename: ", xhr.status, xhr.statusText);
    }
  });

  // ページヘッダーをロード
  $("#page-header").load('/common/page-header.html', function(response, status, xhr) {
    if (status == "error") {
      console.error("Error loading page header: ", xhr.status, xhr.statusText);
    }
  });

  // ページネーションをロード
  $("#pagination").load('/common/pagination.html', function(response, status, xhr) {
    if (status == "error") {
      console.error("Error loading pagination: ", xhr.status, xhr.statusText);
    }
  });
});